import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { m } from 'framer-motion';
import { ContentfulTeaserSlide } from '@ts/contentful';
import { Paragraph } from '@components';
import Img from '../Img';
import styles from './ElevatorCarousel.module.scss';

type ElevatorCarouselSlideProps = {
	type: 'product' | 'pattern';
	slides: ContentfulTeaserSlide[];
	options: {
		legal?: string;
		isHeaderStatic?: boolean;
		isReversed?: boolean;
	};
};

const ElevatorCarouselSlide = ({ type = 'pattern', slides, options }: ElevatorCarouselSlideProps) => {
	const { legal, isHeaderStatic = false, isReversed = false } = options;
	const isProduct = type === 'product';
	const shallowCopy = [...slides];

	if (isProduct) {
		shallowCopy.reverse();
		shallowCopy.unshift(shallowCopy.pop());
	}
	const slidesInMappingOrder = isProduct ? shallowCopy : slides;

	const legalCopy = isProduct && <Paragraph>{legal}</Paragraph>;
	const mappedSlides = isProduct
		? slidesInMappingOrder.map(({ backgroundColor, frame }, i) => (
				<SwiperSlide
					key={`${type}-slide-${i}`}
					className={`${styles.slide} swiper-no-swiping`}
					style={{ backgroundColor }}
				>
					<Img src={frame.url} alt={frame.title} width={frame.width} height={frame.height} noSrcset={true} />
				</SwiperSlide>
			))
		: slides.map(({ pattern }, i) => (
				<SwiperSlide key={`pattern-slide-${i}`} className={`${styles.slide} swiper-no-swiping`}>
					{({ isActive }) => (
						<div className={styles.pattern}>
							<m.img
								src={pattern.url}
								alt='background pattern matching top frame style'
								initial={{ objectPosition: '0 0' }}
								animate={isActive ? 'active' : 'inactive'}
								variants={{
									active: {
										objectPosition: isHeaderStatic ? '0 0' : '0 -96px',
									},
									inactive: {
										objectPosition: '0 0',
									},
								}}
								transition={{ duration: 6.2 }}
							/>
						</div>
					)}
				</SwiperSlide>
			));

	return slides ? (
		<Swiper
			loop
			className={styles.swiper}
			speed={750}
			direction='vertical'
			modules={[Autoplay]}
			autoplay={
				!isHeaderStatic
					? {
							reverseDirection: isReversed,
							disableOnInteraction: false,
							delay: 6000,
						}
					: false
			}
		>
			{mappedSlides}
			{legalCopy}
		</Swiper>
	) : null;
};

export default ElevatorCarouselSlide;
