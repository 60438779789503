import { ContentfulRichTextRenderer, Flex, Heading, SEO } from '@components';
import { NormalizedContentfulPageBlocks } from '@ts/index';
import styles from './ArticlePage.module.scss';

const ArticlePage = ({ blocks, title, seoMetadata }: NormalizedContentfulPageBlocks) => {
	const articleComponents = blocks?.length
		? blocks.map(block => {
				switch (block.componentType) {
					case 'componentText':
						return ContentfulRichTextRenderer(false, { ...block });
					default:
						return null;
				}
			})
		: null;

	return (
		<>
			<SEO
				title={seoMetadata?.seoTitle ?? title}
				noindex={seoMetadata?.no_index}
				nofollow={seoMetadata?.no_follow}
				{...(!!seoMetadata?.description ? { description: seoMetadata?.description } : {})}
			/>
			<Flex column className={styles['container']}>
				<Heading tag='h1'>{title}</Heading>
				{articleComponents}
			</Flex>
		</>
	);
};

export default ArticlePage;
