import { Flex } from '@components';
import { ContentfulTeaserSlide } from '@ts/contentful';
import ElevatorCarouselSlide from './ElevatorCarouselSlide';
import styles from './ElevatorCarousel.module.scss';
import 'swiper/css';

type ElevatorCarouselProps = {
	legalCopy: string;
	slides: ContentfulTeaserSlide[];
	staticHeader: boolean;
	useCarouselSeparator?: boolean;
};

const ElevatorCarousel = ({ legalCopy, slides, staticHeader, useCarouselSeparator = false }: ElevatorCarouselProps) => {
	return (
		<Flex className={styles.container}>
			<ElevatorCarouselSlide
				type='product'
				slides={slides}
				options={{
					legal: legalCopy,
					isReversed: true,
				}}
			/>
			{useCarouselSeparator ? <div className={styles.separator} /> : null}
			<ElevatorCarouselSlide
				type='pattern'
				slides={slides}
				options={{
					legal: legalCopy,
					isHeaderStatic: staticHeader,
				}}
			/>
		</Flex>
	);
};

export default ElevatorCarousel;
